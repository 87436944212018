<template>
  <div class="order__button_container" @mouseover="setHover(true)" @mouseleave="setHover(false)">
    <svg width="90" height="90" viewBox="0 0 90 90"
         :class="`order__button_border ${hovered ? 'order__button_border_close' : ''}`"
         fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="89" height="89" rx="9.27778"
            stroke="#646464" stroke-dasharray="7 7"/>
    </svg>
    <slot name="img" />
  </div>
</template>

<script>
export default {
  name: 'ButtonCard',
  props: {
    startTimer: Function,
    stopTimer: Function,
    index: Number,
    imagesLength: Number,
  },
  data() {
    return {
      hovered: false,
    };
  },
  methods: {
    setHover(hover) {
      this.hovered = hover;
      if (hover) {
        this.$store.commit('home/setActiveImage', this.index);
        this.$emit('setActiveImage', this.index);
      } else {
        this.stopTimer();
        if (this.index < this.imagesLength - 1) {
          this.startTimer(this.index + 1, 5000, true);
        } else {
          this.startTimer(0, 5000, true);
        }
      }
    },
  },
};
</script>
